// .navbar
$navbar-min-height: 50px;
$navbar-color: $app-color-white;
$navbar-background: $app-background-gradient;
$navbar-borders: (
        "top": none,
        "right": none,
        "bottom": none,
        "left": none
);

$navbar__paddings: (
        "top":  0,
        "right": 0,
        "bottom": 0,
        "left": 0
);

// .navbar up(sm)
$navbar__up_sm__paddings: (
        "top": 0,
        "right": 0,
        "bottom": 0,
        "left": 0
);

// .navbar .navbar-brand
$navbar-brand-color: $navbar-color;
$navbar-brand-hover-color: $navbar-brand-color;
$navbar-brand-font-weight: bold;
$navbar-brand__sm-font-size: 18px;
$navbar-brand-name: "Digital Demo Studio";
$navbar-brand-image: null;
$navbar-brand-image-width: 0;
$navbar-brand-image-height: 0;

$navbar-brand-image-xs: null;
$navbar-brand-image-xs-width: null;
$navbar-brand-image-xs-height: null;

// .navbar .navbar-toggler
$navbar-toggler-border-color: transparent;

// Navbar Toggler Icon
$navbar-toggler-background-image: $navbar-dark-toggler-icon-bg;

// Navbar NavLink
$navbar-nav-link-color: $navbar-color;
$navbar-nav-link-hover-color: $navbar-nav-link-color;
$navbar-nav-link-font-size: 16px;
$navbar-nav-link-line-height: 20px;

// Navbar NavBordered
$navbar-nav-bordered__down_sm__border-top: none;
$navbar-nav-bordered__down_sm__margins: (
        "top": 0,
        "right": -1 * $spacer,
        "bottom": 0,
        "left": -1 * $spacer
);
$navbar-nav-bordered__down_sm__paddings: (
        "top": $spacer * 0.25,
        "right": $spacer,
        "bottom": 0,
        "left": $spacer
);


// Navbar Cart Badge
$navbar-cart-badge-font-size: 8px;