.approvals {
  .approvals-container {
    @include media-breakpoint-down(sm) {
      overflow-x: auto;
    }
  }

  input[type="text"] {
    min-width: 100px;
  }
}