.orders-history {
  .title {
    font-weight: bold;
    padding-bottom: $spacer;
  }

  .error {
    color: $danger;
    font-size: $small-font-size;
  }
}

.orders-history-item {
  & + & {
    margin-top: $orders-history-item-margin-top;
  }
}