.banners {
  background: $app-background-gradient;
  padding: $banners-padding-y $banners-padding-x;
  font-size: $banners-font-size;

  @each $property, $value in $banners-borders {
    border-#{$property}: $value;
  }

  .banner {
    border-radius: $banners-banner-border-radius;
    box-shadow: $banners-banner-drop-shadow;
    background-color: $banners-banner-color;

    @each $property, $value in $banners-banner-margins {
      margin-#{$property}: $value;
    }
    @each $property, $value in $banners-banner-paddings {
      padding-#{$property}: $value;
    }

    & + & {
      @each $property, $value in $banners-banner__plus__borders {
        border#{$property}: $value;
      }
      @each $property, $value in $banners-banner__plus__margins {
        margin-#{$property}: $value;
      }
      @each $property, $value in $banners-banner__plus__paddings {
        padding-#{$property}: $value;
      }
    }

    .link {
      font-weight: $banners-banner-link-font-weight;

      &:after {
        position: absolute;
        margin-left: 5px;
        margin-top: 3px;
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHBhdGggZD0iTTkuMzMzMjUgMi42NjY1TDguMzkzMjUgMy42MDY1TDEyLjExMzMgNy4zMzMxN0gxLjMzMzI1VjguNjY2NUgxMi4xMTMzTDguMzkzMjUgMTIuMzkzMkw5LjMzMzI1IDEzLjMzMzJMMTQuNjY2NiA3Ljk5OTg0TDkuMzMzMjUgMi42NjY1WiIgZmlsbD0iIzVGMjQ5RiIvPiA8L3N2Zz4=');
      }
    }
  }
}