.page-navbar {
  min-height: $navbar-min-height;
  background: $navbar-background;

  .navbar {
    position: relative;
    color: $navbar-color;

    @each $property, $value in $navbar__paddings {
      padding-#{$property}: $value;
    }

    @each $property, $value in $navbar-borders {
      border-#{$property}: $value;
    }

    @include media-breakpoint-up(sm) {
      @each $property, $value in $navbar__up_sm__paddings {
        padding-#{$property}: $value;
      }
    }

    .navbar-brand {
      color: $navbar-brand-color;

      @include hover-focus() {
        color: $navbar-brand-hover-color;
      }
    }

    .navbar-toggler {
      border-color: $navbar-toggler-border-color;

      .navbar-toggler-icon {
        background-image: $navbar-toggler-background-image;
      }
    }

    .icon-basket,
    .icon-user {
      @include media-breakpoint-up(md) {
        font-size: 115%;
      }
    }

    .nav-item {
      .nav-link {
        color: $navbar-nav-link-color;
        font-size: $navbar-nav-link-font-size;
        line-height: $navbar-nav-link-line-height;

        @include hover-focus() {
          color: $navbar-nav-link-hover-color;
        }
      }

      .dropdown-menu {

        &.dropdown-menu-collapsed {
          @include media-breakpoint-down(md) {
            background: transparent;
            border: none;
          }

          .dropdown-item {
            color: $navbar-color;
            &.active {
              font-weight: bold;
              background: none;
            }
          }
        }
      }
    }

    .router-link-exact-active {
      font-weight: bold;
    }

    .navbar-nav-bordered {
      @include media-breakpoint-down(sm) {
        border-top: $navbar-nav-bordered__down_sm__border-top;
        @each $property, $value in $navbar-nav-bordered__down_sm__margins {
          margin-#{$property}: $value;
        }
        @each $property, $value in $navbar-nav-bordered__down_sm__paddings {
          padding-#{$property}: $value;
        }
      }
    }

    &-brand {
      padding: 0;
      font-weight: $navbar-brand-font-weight;

      @include media-breakpoint-down(sm) {
        font-size: $navbar-brand__sm-font-size !important;
      }

      &:after {

        @if $navbar-brand-image-xs {
          @include media-breakpoint-down(xs) {
            display: block;
            font-size: 0;
            content: "";
            background: url($navbar-brand-image-xs);
            background-size: contain;
            background-repeat: no-repeat;

            @if $navbar-brand-image-xs-width {
              width: $navbar-brand-image-xs-width;
            } @else {
              width: $navbar-brand-image-width;
            }

            @if $navbar-brand-image-xs-height {
              height: $navbar-brand-image-xs-height;
            } @else {
              height: $navbar-brand-image-height;
            }
          }

          @if $navbar-brand-image {
            display: block;
            font-size: 0;
            content: "";
            background: url($navbar-brand-image);
            width: $navbar-brand-image-width;
            height: $navbar-brand-image-height;
            background-size: contain;
            background-repeat: no-repeat;
          }
        } @else if $navbar-brand-image {
          display: block;
          font-size: 0;
          content: "";
          background: url($navbar-brand-image);
          width: $navbar-brand-image-width;
          height: $navbar-brand-image-height;
          background-size: contain;
          background-repeat: no-repeat;
        } @else {
          content: $navbar-brand-name;
        }
      }
    }

    .nav-link-text {
      display: inline-block;
      padding-top: 2px;
    }

    .navbar-link-cart {
      position: relative;

      .badge {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, 50%);
        font-size: $navbar-cart-badge-font-size;
      }
    }
  }
}